<template>
  <div v-loading="loading">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header">
        <div class="card-title">
          <span class="card-icon">
            <i class="flaticon2-delivery-package text-primary"></i>
          </span>
          <h3 class="card-label"><b class="title-header">権限設定</b></h3>
        </div>
      </div>
      <div class="card-body">
        <el-form
          ref="ruleForm"
          label-width="30%"
          class="demo-ruleForm demo-form"
          :model="ruleForm"
          @keyup.enter.native="confirm_modal"
        >
          <div class="row mb-8">
            <div class="col-12 col-md-10 col-xl-5">
              <label  class="el-form-item__label text-left" style="width: 30%;">権限名 <span class="error-message">*</span></label>
              <el-form-item class="custom_reponsive" prop="role_name">
                <el-input
                  maxlength="40"
                  class="bg-gray-item"
                  v-model="ruleForm.role_name"
                  placeholder="権限名を入力"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-10 col-xl-5">
              <label  class="el-form-item__label text-left m-0" style="width: 30%;">ステータス <span class="error-message">*</span></label>
              <el-form-item class="custom_reponsive" prop="role_status">
                <el-select
                  maxlength="40%"
                  class="bg-gray-item"
                  v-model="ruleForm.role_status"
                  style="width: 100%"
                >
                  <el-option
                    v-for="status in ArrStatus"
                    :value="status.value"
                    :label="status.label"
                    :key="status.value"
                    >{{ status.label }}</el-option
                  >
                </el-select>
              </el-form-item>
            </div>
          </div>
            <div class="row">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <el-form-item class="item-title-form item-fome-base m-0" label="" prop="copy_group_name">
                        <el-checkbox v-model="ruleForm.kengen_flg">承認プロセス・権限・アカウント・テナントの変更を許可する</el-checkbox>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <el-form-item class="item-title-form item-fome-base m-0" label="" prop="copy_group_name">
                        <el-checkbox v-model="ruleForm.form_flg">フォーム作成・編集を許可する</el-checkbox>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <el-form-item class="item-title-form item-fome-base m-0" label="" prop="copy_group_name">
                        <el-checkbox v-model="ruleForm.answer_flg">回答の閲覧を許可する</el-checkbox>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <el-form-item class="item-title-form item-fome-base m-0" label="" prop="copy_group_name">
                        <el-checkbox v-model="ruleForm.tennant_flg">テナント検索・設定を許可する</el-checkbox>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 col-xl-5">
                    <label class="el-form-item__label"></label>
                    <el-form-item class="item-title-form item-fome-base m-0" label="" prop="copy_group_name">
                        <el-checkbox v-model="ruleForm.mail_flg">メール設定を許可とする</el-checkbox>
                    </el-form-item>
                </div>
            </div>
            <div v-if="currentUser.super_user" class="row mb-8">
                <div class="col-12 col-md-10 col-xl-5">
                    <label class="el-form-item__label" for=""> テナント</label>
                    <el-form-item class="item-title-form item-fome-base" label=""
                                  prop="tenant_code">
                        <el-select class="w-100 bg-gray-item"  v-model="ruleForm.tenant_code" placeholder="選択してください" :disabled="ruleForm.kengen_flg == false">
                            <el-option label="なし" value=" "></el-option>
                            <el-option
                                v-for="(item, index) in listTenant"
                                :key="index"
                                :label="item.tenant_name"
                                :value="item.tenant_code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
          <el-form-item prop="action" class="button-search" label-width="0px">
            <el-button
              class="ml-20 pl-10 pr-10"
              type="primary"
              @click.prevent="confirm_modal"
              >入力完了</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Popup id="confirm" title="確認" :content="title" :title_button="close_popup">
    <b-button @click.prevent="createRole()" class="btn-primary">{{ $t("YES") }}</b-button>
  </Popup>
      <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                 :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
          <template slot="header">
              <div class="textwidget text-center">
                  {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
              </div>
          </template>
      </my-dialog>
  </div>
</template>
<script>

import * as constants from "@/core/config/constant";
import { CREATE_ROLE } from "@/core/services/store/role.module";
import Popup from "@/view/components/Popup";
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_TENANT} from "@/core/services/store/mtenant.module";
export default {
  components: {
    Popup
  },
  data() {
    return {
      ruleForm: {
        role_name: "",
        role_status: '',
        kengen_flg: false,
        form_flg: false,
        answer_flg: false,
        mail_flg: false,
        tennant_flg: false,
        tenant_code: '',
      },
      constants: constants,
      loading: false,
      isSubmit: false,
      title: "",
      ArrStatus: [],
      close_popup: this.$t("close_popup_add_role"),
      not_permisson_popup: false,
      listApproval:[],
    };
  },
  watch: {
    // "ruleForm.kengen_flg"() {
    //   if (this.ruleForm.kengen_flg == false) {
    //     this.ruleForm.tenant_code = ''
    //   }
    // }
  },
  computed: {
    ...mapGetters(["role","currentUser"]),
  },
    async created() {
      this.listTenant = await this.getAllTenant();
      if (!this.hasPermissonCheck('kengen_flg')){
          this.not_permisson_popup = true
      }
    this.ArrStatus = this.constants.ROLE_STATUS;
  },
  methods: {
      ...mapActions([GET_ALL_TENANT]),
    createRole() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.turnOffErrorMessage("ruleForm");
          let payload = this.ruleForm;
          payload.tenant_code = payload.kengen_flg ? payload.tenant_code : ' '
          this.$store.dispatch(CREATE_ROLE, payload).then((data) => {
              this.loading = false;
              if (data && data.data && data.data.data) {
                if (data.data.code === constants.STATUS_CODE.SUCCESS) {
                  this.notifySuccess(this.$t("REGISTER_SUCCESS"));
                  let self = this;
                  setTimeout(function () {
                    self.$router.push({
                      name: "list-role",
                    });
                  }, 500);
                  return;
                }
                if (data.data.code === constants.STATUS_CODE.BAD_REQUEST) {
                  this.showErrorMessage(data.data.data, "ruleForm");
                  this.$bvModal.hide("confirm");
                } else {
                  this.notifyError(data.message);
                  this.$bvModal.hide("confirm");
                   this.loading = false;
                }
              } else {
                this.notifyError(this.$t("ERROR_SERVER"))
                this.$bvModal.hide("confirm");
                 this.loading = false;
              }
            })
            .catch((e) => {
              this.notifyError(e);
              this.$bvModal.hide("confirm");
               this.loading = false;
            });
        } else {
          this.$bvModal.hide("confirm");
        }
      });
    },
    confirm_modal() {
      if (this.ruleForm.role_name == "") {
        this.title = this.$t("TITLE_CONFIRM_ROLE",{role_name:this.ruleForm.role_name});
        this.$bvModal.show("confirm");
      }else{
        this.title = this.$t("TITLE_CONFIRM_ROLE",{role_name:this.ruleForm.role_name});
        this.$bvModal.show("confirm");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-custom {
  border-radius: 15px;
}

.card-custom > .card-header {
  padding: 0;
  margin: 0px 21px;
}
@media only screen and (max-width: 990px) and (min-width: 270px){
.bg-gray-item, .el-input--suffix {
     width: 100%;
    float: right;
}
.el-form-item__content {
    width: 100% !important;
    float: right !important;
    margin-left: 0px !important;
}
.ml-20, .mx-20 {
     margin-left: 0px !important;
}
label.el-form-item__label.text-left {
    width: 50% !important;
}
}
</style>
